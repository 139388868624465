export function handleFormSubmit() {
    const form = document.querySelector('#contact-form')
    form.addEventListener('submit', function (e) {
      e.preventDefault()
      sendData(form)
    })
}

function sendData(form) {
    const XHR = new XMLHttpRequest()
    const FD = new FormData(form)
    XHR.addEventListener('load', () => {
      form.classList.add('inactive')
      const success = document.querySelector('.post-sent');
      success.classList.remove('is-hidden')
    })
    XHR.addEventListener('error', () => {
      form.classList.add('inactive')
      const error = document.querySelector('.post-error');
      error.classList.remove('is-hidden')
    })
    XHR.open('POST', '#')
    XHR.send(FD)
}
